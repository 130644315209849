










































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import CreateGroupe from "./CreateGroupe.vue";
import UpdateGroupe from "./EditGroupe.vue";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Groupe } from "@/api/models/groupes/groupe";

@Component({
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateGroupe,
    UpdateGroupe,
  },
})
export default class ListGroupes extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewGroupeSidebarActive = false;
  isUpdateGroupeSidebarActive = false;
  selectedGroupeId = "";

  tableColumns = [{ key: "libelle", sortable: true },{key: "countUtilisateurs", label: "Nombre de membres", sortable: false}, { key: "actions" }];
  paginatedListGroupes = new PaginatedList<Groupe>();

  async created() {
    await this.loadListGroupes({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListGroupes.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListGroupes.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListGroupes({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

   selectGroupe(groupeId: string) {
    this.$router.push({
      name: "details-groupes",
      params: { id: groupeId },
    });
  }

  editGroupe(groupeId: string) {
    this.selectedGroupeId = groupeId;
    this.isUpdateGroupeSidebarActive = true;
  }

  async loadListGroupes(params: any) {
    if (!params || params?.reset) {
      this.paginatedListGroupes = new PaginatedList<Groupe>();
    }
    await this.$http.myressif.groupes
      .paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 10)
      .then((response: PaginatedList<Groupe>) => {
        this.paginatedListGroupes = response;
      });
  }



  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce groupe ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.groupes
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un groupe",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListGroupes({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
